import React from 'react';
import './skills.css';
import "font-awesome/css/font-awesome.min.css";



function Skills(props) {
    return (<div className="skills">
        <ul>
                <li><i className="devicon-javascript-plain icon skills-buttons"><span className="icon-title"> Javascript</span></i></li>
                <li><i className="devicon-ruby-plain-wordmark icon skills-buttons"><span className="icon-title"> Ruby</span></i></li>
                <li><i className="devicon-nodejs-plain icon skills-buttons"><span className="icon-title"> Node.js</span></i></li>
                <li><i className="devicon-express-original icon skills-buttons"><span className="icon-title"> Express.js</span></i></li>
                <li><i className="devicon-react-original icon skills-buttons"><span className="icon-title"> React</span></i></li>
                <li><i className="devicon-rails-plain-wordmark icon skills-buttons"><span className="icon-title"> Ruby on Rails</span></i></li>
                <li><i className="devicon-css3-plain icon skills-buttons"><span className="icon-title"> CSS3</span></i></li>
                <li><i className="devicon-html5-plain icon skills-buttons"><span className="icon-title"> HTML5</span></i></li>
                <li><i className="devicon-mongodb-plain icon skills-buttons"><span className="icon-title"> MongoDB</span></i></li>
                <li><i className="devicon-sass-original icon skills-buttons"><span className="icon-title"> SASS</span></i></li>
                <li><i className="devicon-postgresql-plain icon skills-buttons"><span className="icon-title"> Postgresql</span></i></li>
                <li><i className="devicon-heroku-original icon skills-buttons"><span className="icon-title"> Heroku</span></i></li>
                <li><i className = "devicon-webpack-plain icon skills-buttons"> <span className="icon-title"> Webpack</span></i></li>
                <li><i className="devicon-d3js-plain icon skills-buttons"><span className="icon-title"> D3.js</span></i></li>
                <li><i className="devicon-photoshop-line icon skills-buttons"><span className="icon-title"> Photoshop</span></i></li>
                <li><i className="devicon-illustrator-line icon skills-buttons"><span className="icon-title"> Illustrator</span></i></li>
        </ul>
             </div>
    )
}


export default Skills;

import React from 'react';
import './project.css';


const Project = (props) => {

        let filename = props.title.split(" ").map(word => word.toLowerCase()).join("-");

        return (
            <div className="project">
                <div className="project-links">            
                    <a href={props.liveLink} target="blank">Live</a> |&nbsp;
                    <a href={props.githubLink} target="blank">GitHub</a></div>
                <img src={require(`./assets/${filename}.png`)} alt={props.title}/>
                <p>{props.description}</p>
            </div>)
        
        

}


export default Project;



import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import {
    Switch,
    Route
} from "react-router-dom";
import { withRouter } from 'react-router';
import Project from './project';
import './animated_projects.css';


const AnimatedProjects = withRouter(({ location })  => {
        return (     <div className="project-box">
              <ReactCSSTransitionGroup 
                className="span"
                transitionName='project-transition'
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
                  
                <Switch location={location}
                  key={location.key}>     
                  <Route path = "/unlimitlist" ignoreScrollBehavior >
                      <Project 
                        title="Unlimitlist"
                        liveLink = "https://unlimitlist.herokuapp.com/#/"
                        githubLink = "https://github.com/nicolsama/unlimitlist"
                        description = "A note-taking app for making and editing lists, marking items as complete, and organizing ideas into sublists."
                        key={1}
                      />
                  </Route>
                  <Route path="/haiclue" ignoreScrollBehavior >
                    <Project 
                      title="Haiclue"
                      liveLink = "https://haiclue.herokuapp.com/#/"
                      githubLink = "https://github.com/nirkraw/Haiclue"
                      description = "A multiplayer word association game where players guess clues created from word tiles arranged by other players."
                      key={2}
                    />
                  </Route>
                  <Route path="/scuuba-duu" ignoreScrollBehavior >
                      <Project 
                        title="Scuuba Duu"
                        liveLink = "https://nicolsama.github.io/scuuba-duu/"
                        githubLink = "https://github.com/nicolsama/scuuba-duu"
                        description="A visual browser game. Users control a diver around an underwater landscape trying to collect points and avoid enemies."
                        key={1}
                        />
                  </Route>
                  <Route path='/'></Route> 
                </Switch>
              </ReactCSSTransitionGroup>    </div>)
  }
)

export default AnimatedProjects; 
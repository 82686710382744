import React from 'react';
import './header.css';

class Header extends React.Component {

    render() {

        return (
            <div>
                <h1 className='title'>
                <br/><span className='name'>Nicole Samanich</span>
                <br/><span className='software-developer'>Software Developer</span></h1>
                <div className='header-container'>
                    <div className='header-bar'></div>
                </div>                
            </div>
    );
    }
}

export default Header;
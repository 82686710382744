import React from 'react';
import './App.css';
import './reset.css';
import Header from './header';
import Skills from './skills';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import AnimatedProjects from './animated_projects';
import {
  BrowserRouter as Router,
  Route,
  Link
} from "react-router-dom";

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showAbout: false, 
      showProjects: false, 
      showSkills: false, 
      showContact: false,
      displayProject: "",
      rotate: false
    }
    this.selectClickedProjectBound = this.selectClickedProject.bind(this);
  }

  selectClickedProject(title) {
      this.setState({displayProject: title});
  }

  render() {
    let { projects } = this.props; 
    let projectLis = projects.map((title, index) => {
    let selected = (this.state.displayProject === title || (window.location.pathname === `/${title}`)) ? "current-project" : null;
       
      return <Link 
                  key={index}
                  className={`buttons ${selected}`}
                  to={`/${title}`}>{title}
                  </Link>
          });

    let projectContainer = (this.state.showProjects) ? (
              <ul 
                className = "project-container" 
                onClick={() => this.selectClickedProjectBound()}>
                      {projectLis}
              </ul>): null; 

    let aboutContainer = this.state.showAbout ? (
      <div className="about-container">
        <p>I am a <mark>passionate programmer</mark> and <mark>creative human</mark>.</p>
        <p>
          I have experience with development in React, Redux, Ruby on
          Rails, Javascript, Node.js, and Express.js. I love bringing my <mark>art & design</mark> background
          together with the <mark>structure of programming</mark>, and enjoy collaborating with a <mark>diverse</mark> team.
        </p>
        <p>
          When I'm not coding I am <a className='about-link' href="https://nikkisamanich.art/">painting</a>, listening to music, or exploring
          new places.
        </p>
        <div className="viewCV-container">
          <Link to="/Nicole_Samanich-Resume.pdf" target="blank" className="buttons input-button">
            View Resume
          </Link>
        </div>
      </div>
    ) : null;

    let skillsLis = (this.state.showSkills) ? (<Skills/>) : null;
    let contactContainer = (this.state.showContact) ? (
    <div className='contact-container'>
      <a href="mailto:nicolesamanich@gmail.com" 
        className='buttons input-button'target="blank"> Email 
        <i className="far fa-paper-plane"></i>
      </a>
          
      <a href="https://www.linkedin.com/in/nicole-samanich-b21078136" 
        className='buttons input-button'target="blank"> Connect
        <i className="fab fa-linkedin-in"></i>
      </a>
    </div>) : null; 

      const {showAbout, showContact, showProjects, showSkills} = this.state;

    let aboutArrow = <svg xmlns="http://www.w3.org/2000/svg" 
      width="40" height="24" 
      viewBox="0 0 24 24"
      className={showAbout ? "rotate" : "false"}>
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
      </svg> ;
    let projectsArrow = <svg xmlns="http://www.w3.org/2000/svg" 
      width="40" height="24" 
      viewBox="0 0 24 24"
      className={showProjects ? "rotate" : "false"}>
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
      </svg>;
    let skillsArrow = <svg xmlns="http://www.w3.org/2000/svg" 
      width="40" height="24" 
      viewBox="0 0 24 24"
      className={showSkills ? "rotate" : "false"}>
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
      </svg> ;
    let contactArrow = <svg xmlns="http://www.w3.org/2000/svg" 
      width="40" height="24" 
      viewBox="0 0 24 24"
      className={showContact ? "rotate" : "false"}>
        <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"/>
      </svg> ;


    return (
      <Router>
        <div className="container">
          <Route path ="/">
            <Header />
            <h2 className="section-title"
                  onClick={() => (this.setState({showAbout: !this.state.showAbout}))}>
              <span>
                {aboutArrow}
              </span>
              about</h2>
              <ReactCSSTransitionGroup
                transitionName='section-transition'
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
                {aboutContainer}
              </ReactCSSTransitionGroup>
            <h2 className="section-title"
                onClick={() => (this.setState({showSkills: !this.state.showSkills}))}>
              <span>
                {skillsArrow}
              </span>
              skills</h2>
              <ReactCSSTransitionGroup
                transitionName='section-transition'
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
                {skillsLis}
              </ReactCSSTransitionGroup>
            <h2 className="section-title"
                onClick={() => (this.setState({showProjects: !this.state.showProjects}))}>
              <span>
                {projectsArrow}
              </span>
              projects</h2>
              <ReactCSSTransitionGroup
                transitionName='section-transition'
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
                {projectContainer}
              </ReactCSSTransitionGroup>
                {this.state.showProjects ?<AnimatedProjects/> : null}
            <h2 className="section-title"
                onClick={() => (this.setState({showContact: !this.state.showContact}))}>
              <span>
                {contactArrow}
              </span>
              contact</h2>
              <ReactCSSTransitionGroup
                transitionName='section-transition'
                transitionEnterTimeout={500}
                transitionLeaveTimeout={500}>
              {contactContainer}
              </ReactCSSTransitionGroup>
          </Route>
         

        </div>
      </Router>
    )

  };
}

export default App;
